var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"c1dda5c9f5b1d0220c2f2595adcc829c822e4fd1"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN || "";
const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT || "local";
const SENTRY_SAMPLE_RATE =
  ENVIRONMENT !== "production"
    ? 0
    : process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE || 0.1;

Sentry.init({
  dsn: SENTRY_DSN,
  environment: ENVIRONMENT,
  // We may want to adjust this lower in the future.
  tracesSampleRate: SENTRY_SAMPLE_RATE,
  tunnel: "/sentry/proxy",
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: ENVIRONMENT === "production" ? 1.0 : 0,
  integrations: [
    new Sentry.Replay({
      networkDetailAllowUrls: [window.location.origin],
      maskAllText: false,
      maskAllMedia: false,
    }),
  ],
  beforeSend: (event) => {
    // This is a Next.js specific error that can be safely ignored
    if (
      event.exception?.values?.[0].value.includes(
        "Invariant: attempted to hard navigate to the same URL"
      )
    ) {
      return null;
    }

    // This is an ios specific error that can be safely ignored
    if (event.exception?.values?.[0].value.includes("Failed to fetch")) {
      return null;
    }

    // chunk load errors
    if (event.exception?.values?.[0].value.includes("Loading chunk")) {
      return null;
    }

    return event;
  },
});
